import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import OrderPropisTwo from "../components/orderPropisTwo"
import Slider from "react-slick"

import ImagePropis from "../images/zhi-shi.jpg"

import russian1 from "../images/zhi-1.jpg"
import russian2 from "../images/zhi-2.jpg"
import russian3 from "../images/zhi-3.jpg"
import russian4 from "../images/zhi-4.jpg"

const imgZhi = [
  {
    id: 1,
    pic: russian1,
  },
  {
    id: 2,
    pic: russian2,
  },
  {
    id: 3,
    pic: russian3,
  },
  {
    id: 4,
    pic: russian4,
  },
]
const PropisTwo = () => {
  const imageMap = imgZhi.map(image => {
    return (
      <div key={image.id}>
        <div className="flex justify-center">
          <img className="max-h-350" src={image.pic} alt="some" />
        </div>
      </div>
    )
  })

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Layout>
      <Seo title="Прописи ЖИ-ШИ, ЧА-ЩА, ЧУ-ЩУ" />
      <div className="mb-4">
        <a href="/">
          <button
            type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
          >
            На главную
          </button>
        </a>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2">
          <img src={ImagePropis} alt="cover" />
        </div>
        <div className="pl-0 md:pl-8 md:w-1/2">
          <h1 className="mb-4 text-2xl font-black tracking-tighter text-black md:text-5xl title-font">
            Прописи ЖИ-ШИ, ЧА-ЩА, ЧУ-ЩУ
          </h1>
          <p className="mt-12 mb-0">
            Прописи ЖИ-ШИ, ЧА-ЩА, ЧУ-ЩУ- самый лучший вариант запомнить правило
            и научиться правильно писать с ним слова.
          </p>
          <br />
          <span className="">
            В нашем учебном пособии вы пропишите разные слова с этими правилами.
            Каждое слово это упражнение на три строчки: две пунктирных и одна
            пустая для самостоятельной работы.
          </span>
          <br />
          <br />
          <span>
            Каждое слово с правилом прописано в рабочей тетради сначала в
            печатном варианте для легкости прочтения, потом в письменном.
          </span>
          <br />
          <br />
          <span>
            Соединения ЖИ-ШИ, ЧА-ЩА, ЧУ-ЩУ выделены и подчеркнуты в словах для
            лучшего усвоения материала. Прописав данную пропись вы улучшите
            навык письма и выучите правильное употребление сочетаний ЖИ-ШИ,
            ЧА-ЩА, ЧУ-ЩУ в словах.
          </span>
          <br />
          <br />
          <span>Учите правило и тренируйте почерк!!!</span>
        </div>
      </div>
      <OrderPropisTwo />
      <div className="mt-24 mb-24">
        <Slider {...settings}>{imageMap}</Slider>
      </div>
    </Layout>
  )
}

export default PropisTwo
